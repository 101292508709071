var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0 ma-0"},[_c('tr',[_c('td',{},[_c('div',[_vm._v(_vm._s(_vm.item.clave))])]),_c('td',{},[_c('div',[_vm._v(_vm._s(_vm.item.nombre))])]),_c('td',{},[_c('div',[_vm._v(_vm._s(_vm.item.empresa))])]),_c('td',{},[_c('div',[_vm._v(_vm._s(_vm.item.registro_patronal))])]),_c('td',[_c('div',{staticClass:"tblOpciones"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.agregar()}}},on),[_c('v-icon',{staticClass:"iconoDelgadoBoton"},[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Agregar beneficiario")])]),(_vm.beneficiarios.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){_vm.isOpen ? _vm.isOpen = false:_vm.isOpen = true}}},on),[_c('v-icon',{staticClass:"tamIconoBoton iconoDelgadoBoton"},[_vm._v(_vm._s(_vm.isOpen ? 'keyboard_arrow_up':'keyboard_arrow_down'))])],1)]}}],null,false,801905789)},[_c('span',[_vm._v("Desplegar")])]):_vm._e()],1)])]),(_vm.isOpen)?[_vm._m(0),_vm._l((_vm.beneficiarios),function(datos){return _c('tr',{key:datos.id,staticClass:"td-short"},[_c('td',[_c('div',[_vm._v(" "+_vm._s(datos.nombre)+" ")])]),_c('td',[_c('div',[_vm._v(" "+_vm._s(datos.telefono)+" ")])]),_c('td',[_c('div',[_vm._v(" "+_vm._s(datos.correo)+" ")])]),_c('td',{staticStyle:{"padding-left":"8px","padding-right":"8px"}},[_c('div',{staticClass:"tblOpciones"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.abrirModal(datos)}}},on),[_c('v-icon',{staticClass:"tamIconoBoton invertirColorBotones"},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.eliminar(datos)}}},on),[_c('v-icon',{staticClass:"tamIconoBoton invertirColorBotonesDelete"},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)])])})]:_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"td-titles"},[_c('td',[_c('div',[_vm._v(" Nombre del beneficiario ")])]),_c('td',[_c('div',[_vm._v(" Teléfono ")])]),_c('td',[_c('div',[_vm._v(" Correo ")])]),_c('td',[_c('div',{staticClass:"tblOpciones"},[_vm._v(" Opciones ")])])])}]

export { render, staticRenderFns }